<template>
  <div class="portalSystem_accountopening">
    <LiefengContent v-show="state == 1">
      <template v-slot:title v-if="topTitle">{{ `${topTitle}机构数、管理员数` }}</template>
      <template v-slot:title v-else>{{ `当前没有选中地区` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <FormItem label="地区选择" :label-width="75">
            <!-- resetNum -->
            <CascaderCity @change="changeCasader" :orgCode="44" :width="200"></CascaderCity>
          </FormItem>
          <Button type="primary" icon="ios-search" @click="searchBtn(1)" style="margin-right: 10px">查询</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <table class="accountopening_table">
          <tr class="accountopening_table_tr">
            <th class="table_th" v-for="(item, index) in columns1" :key="index">{{item}}</th>
          </tr>
          <tr class="accountopening_table_tr" v-for="(item, index) in data1" :key="index" @click="row_click(item)">
            <td class="table_td hover" @click="cell_click(1)">{{item.dimensionId | dimensionValue(dimensionList)}}</td>
            <td class="table_td hover" @click="cell_click(2, 'true')">{{item.statNum || 0}}</td>
            <td class="table_td">{{item.staffNum || 0}}</td>
            <td class="table_td">{{item.workStaffNum || 0}}</td>
            <td class="table_td hover" @click="cell_click(2, 'false')">{{item.statInNum || 0}}</td>
          </tr>
          <tr class="accountopening_table_tr" v-if="data1.length == 0">
            <td class="table_td_wu" colspan="5">{{prompt}}</td>
          </tr>
        </table>

        <div class="accountopening_slele" v-show="echart_state">
          <div id="main" style="width:90%;height:85%;"></div>
          <div class="slele">
            <Select ref="clearable" style="width:200px" v-model="select_value" @on-change="on_change" placeholder="默认排序">
              <Option
                v-for="item in cityList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option>
            </Select>
          </div>
        </div>
      </template>
    </LiefengContent>

    <LiefengContent v-if="state == 2">
      <template v-slot:title>{{ `${dropdownName}机构数、管理员数` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button type="primary" @click="searchBtn(2)" style="margin-right: 10px">返回</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <div class="screening_Dropdown">
          <Dropdown trigger="click" @on-click="dropdown_list" style="margin: 0 40px; font-size: 24px">
            <a href="javascript:void(0)" style="color: black;">
              {{dropdownName}}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem
                v-for="(item, index) in dimensionList"
                :key="index"
                :name="item">{{item.name}}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <table class="accountopening_table">
          <tr class="accountopening_table_tr">
            <th class="table_th">
              <CascaderCity
                @changeCasader="screening_changeCasader"
                :orgCode="orgCode1"
                :placeholder="screeningLabel"
                :resetNum="dropdown_resetNum"
                class="input"
                :disabled="disabledTow"></CascaderCity>
            </th>
            <th class="table_th" v-for="(item, index) in columns2" :key="index">{{item}}</th>
          </tr>
          <tr class="accountopening_table_tr" v-for="(item, index) in data2" :key="index" @click="row_click(item)">
            <td class="table_td">{{item.orgName}}</td>
            <td class="table_td">{{item.statNum || 0}}</td>
            <td class="table_td">{{item.staffNum || 0}}</td>
            <td class="table_td">{{item.workStaffNum || 0}}</td> 
            <td class="table_td">{{item.statInNum || 0}}</td>
          </tr>
          <tr class="accountopening_table_tr" v-if="data2.length == 0">
            <td class="table_td_wu" colspan="5">{{prompt}}</td>
          </tr>
        </table>

        <div class="accountopening_slele" v-show="dropdown_echart_state">
          <div id="dropdown" style="width:90%;height: 100%;"></div>
          <div class="slele">
            <Select style="width:200px" v-model="select_value_dropdown" @on-change="on_change" placeholder="默认排序">
              <Option
                v-for="item in cityList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option>
            </Select>
          </div>
        </div>
      </template>
    </LiefengContent>
    
    <LiefengContent v-if="state == 3">
      <template v-slot:title>{{ open_state? "机构名单" : "已开通账号机构数" }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <FormItem label="地区选择" :label-width="75">
            <CascaderCity
              @changeCasader="institutions_changeCasader"
              :placeholder="screeningLabel"
              :resetNum="institutions_resetNum"
              :orgCode="orgCode2" 
              :width="200"></CascaderCity>
          </FormItem>
          <Dropdown
            trigger="click"
            @on-click="institutions_list"
            class="search_dropdown">
            <a href="javascript:void(0)" class="search_dropdown_a">
              {{dropdownName}}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem
                v-for="(item, index) in dimensionList"
                :key="index"
                :name="item">{{item.name}}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button type="primary" icon="ios-search" @click="searchBtn(3)" style="margin-right: 10px">查询</Button>
          <Button type="primary" @click="daochu" style="margin-right: 10px">导出名单</Button>
          <Button type="primary" @click="searchBtn(2)" style="margin-right: 10px">返回</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
        :talbeColumns="institutions_columns"
        :tableData="data3"
        :hidePage="true"
      ></LiefengTable>
        <!-- <Tablec
          :tableColumn="institutions_columns"
          :tableData="data3"></Tablec> -->
        <div class="total" v-if="data3.length != 0">
          <Page :total="maxCount" show-total @on-change="on_changePage" :page-size="pageSize"/>
        </div>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import CascaderCity from '../components/CascaderCity'
import echarts from "echarts"
import Tablec from "../components/Table"
import LiefengTable from "@/components/LiefengTable";
export default {
  components: {
    LiefengContent,
    CascaderCity,
    Tablec,
    LiefengTable
  },
  filters: {
    dimensionValue(val, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == val) {
          return arr[i].name
        }
      }
    }
  },
  created() {
    this.dimension()
  },
  data () {
    return {
      topArr: [], //地区数据
      topTitle: "",
      columns1: ["机构类型","机构数","管理员数","在职","已开通账号机构数"],
      data1: [],  //table数据
      prompt: "请选择地区，获取数据",
      tableObj: {}, //选中的table一行数据
      dimensionList: [], //维度
      cityList: [
        {value: '1', label: '机构数'},
        {value: '2', label: '管理员数'},
        {value: '3', label: '在职'},
        {value: '4', label: '已开通账号机构数'},
        {value: '0', label: '默认排序'}
      ],
      echart_state: false,
      state: 1,  //控制显示内容
      screeningLabel: "",   //筛选头部选中的地区value,
      select_value: "",

      // 筛选页面数据
      dropdown_dimensionId: "",
      columns2: ["机构数","管理员数","在职","已开通账号机构数"],
      orgCode1: "",   //地区编码
      dropdown_topArr: [],   //筛选头部地区
      dropdownName:  "",   //筛选页面的维度名字
      dropdown_resetNum: 0,  //筛选页面删除的回退到广东省下地区
      data2: [],
      dropdown_echart_state: false,
      select_value_dropdown: "",
      disabledTow: false,

      // 机构页面数据
      orgCode2: "",
      institutions_dimensionId: "",
      institutions_topArr: [],
      institutions_resetNum: 0,  //机构页面删除的回退到广东省下地区
      data3: [],
      institutions_columns: [
        {title: '区',key: 'zoneName'},
        {title: '街道',key: 'streetName'},
        {title: '社区',key: 'communityName'},
        {title: '机构名称',key: 'orgName'},
        {title: '机构类型',key: 'name'},
        {title: '服务范围',key: 'orgScopeName'},
        {title: '管理员数',key: 'staffNum'},
        {title: '在职',key: 'workStaffNum'},
      ],
      page: 1,
      pageSize: 20,
      maxCount: "",
      daochu_state: false,


      // 开通机构数
      open_state: true
    }
  },
  methods: {
    // 请求table数据
    request(val) {
      this.$get("/gateway/api/symanage/pc/manages/selectByOrgCode",{
        orgCode: val
      }).then(res => {
        if (res.code == "200") {
          if (res.dataList.length != 0) {
            this.data1 = res.dataList
            this.echart_state = true
            this.$nextTick(() => {
              this.echart_data("request")
            })
          } else {
            this.data1 = []
            this.$nextTick(() => {
              this.echart_data(1)
            })
            this.echart_state = false
            this.prompt = "当前地区没有数据，请换一个地区"
          }
        } else {
          this.data1 = []
          this.$nextTick(() => {
            this.echart_data(1)
          })
          this.echart_state = false
          this.prompt = res.msg
        }
      })
    },
    // 筛选页面请求
    dropdown_request() {  
      this.disabledTow = true                                                 -
      this.$get("/gateway/api/symanage/pc/manages/selectByDimension",{
        orgCode: this.dropdown_topArr[this.dropdown_topArr.length - 1],
        dimensionId: this.dropdown_dimensionId
      }).then(res => {
        if (res.code == "200") {
          if (res.dataList.length != 0) {
            this.data2 = res.dataList
            this.disabledTow = false
            this.dropdown_echart_state = true
            this.$nextTick(() => {
              this.echart_data("dropdown_request")
            })
          } else {
            this.data2 = []
            this.disabledTow = false
            this.$nextTick(() => {
              this.echart_data(1)
            })
            this.dropdown_echart_state = false
            this.prompt = "当前地区没有数据，请换一个地区"
          }
        } else {
          this.disabledTow = false
          this.data2 = []
          this.$nextTick(() => {
            this.echart_data(1)
          })
          this.dropdown_echart_state = false
          this.prompt = res.msg
        }
      })
    },
    // 机构页面请求
    institutions_request() {
      let url = ""
      if (this.open_state) {
        url = "/gateway/api/symanage/pc/manages/selectStatPage"
      } else {
        url = "/gateway/api/symanage/pc/manages/selectOpenedStatPage"
      }
      this.$get(url, {
        orgCode: this.institutions_topArr[this.institutions_topArr.length - 1],
        dimensionId: this.institutions_dimensionId,
        page: this.page,
        pageSize: this.pageSize
      }).then(res => {
        if ( res.code == "200") {
          for(let i = 0; i < res.dataList.length; i++){
            if(res.dataList[i].staffNum == null) {
              res.dataList[i].staffNum = 0
            }
            if(res.dataList[i].statInNum == null) {
              res.dataList[i].statInNum = 0
            }

            if(res.dataList[i].workStaffNum == null) {
              res.dataList[i].workStaffNum = 0
            }
          }
          this.maxCount = res.maxCount
          this.data3 = res.dataList
          for (let j = 0; j < this.data3.length; j++) {
            for (let i = 0; i< this.dimensionList.length; i++) {
              if (this.data3[j].dimensionId == this.dimensionList[i].id) {
                this.data3[j].name = this.dimensionList[i].name
              }
            }
          }
        } else {
          this.data3 = []
        }
      })
    },
    // 维度请求
    dimension() {
      this.$get("/gateway/org/pc/dimension/selectByOemCode",{
        oemCode: parent.vue.oemInfo.oemCode
      }).then(res => {
        if (res.code == "200") {
          this.dimensionList = res.dataList
        }
      })
    },
    // 头部地区
    changeCasader({val, item, name}) {
      this.topArr = val
      if(val.length == 0) {
        this.topTitle = ""
      }
      
      if(val.length > 0) {
        setTimeout(() => {
          this.screeningLabel = name.input.currentValue
          this.topTitle = name.input.currentValue
        }, 150)
      }
    },
    // 查询/返回
    searchBtn(val) {
      if (val == 1) {
        if (this.topArr.length == 0) {
          this.$Notice.error({
            title: '请选择地区范围',
          });
          return
        }
        this.select_value = ""
        this.request(this.topArr[this.topArr.length - 1])
      } else if (val == 2) {
        if(this.state == 3) {
          if(this.daochu_state == true) {
            this.instance({title: "提示", content: "此操作活中断导出Excel表", state: 2})
            return
          }
        }

        this.state = 1
        if (this.select_value_dropdown) {
          this.select_value_dropdown = ""
        }
        if (this.select_value) {
          this.select_value = ""
        }
        this.page = 1
        this.pageSize = "20"
        this.echart_data("request")
      } else if (val == 3) {
        if(this.daochu_state == true) {
          this.instance({title: "提示", content: "此操作活中断导出Excel表", state: 2})
          return
        }

        if (this.institutions_topArr.length == 0) {
          this.$Notice.error({
            title: '请选择地区范围',
          });
          return
        }
        this.select_value_dropdown = ""
        this.institutions_request()
      }
    },
    // 获取一行数据
    row_click (obj) {
      this.tableObj = obj
    },
    // 单元格数据
    cell_click(val, state) {
      if (val == 1) {
        this.orgCode1 = this.topArr[this.topArr.length - 1]
        this.dropdown_topArr.push(this.topArr[this.topArr.length - 1])
        setTimeout(() => {
          for (let i = 0; i < this.dimensionList.length; i++) {
            if (this.tableObj.dimensionId == this.dimensionList[i].id) {
              this.dropdownName = this.dimensionList[i].name
              this.dropdown_dimensionId = this.tableObj.dimensionId
              this.state = 2
              this.dropdown_request()
              return 
            }
          }
        }, 250)
        
      } else if (val == 2) {
        if (state == "true") {
          this.open_state = true
        } else if (state == "false") {
          this.open_state = false
        }
        
        this.orgCode2 = this.topArr[this.topArr.length - 1]
        this.institutions_topArr.push(this.topArr[this.topArr.length - 1])
        setTimeout(() => {
          for (let i = 0; i < this.dimensionList.length; i++) {
            if (this.tableObj.dimensionId == this.dimensionList[i].id) {
              this.dropdownName = this.dimensionList[i].name
              this.institutions_dimensionId = this.tableObj.dimensionId
              this.state = 3
              this.institutions_request()
              return 
            }
          }
        }, 250)
        
      }
    },
    // 动态渲染大屏
    echart_data(val, arr) {
      // arr是筛选后的新数组

      let state = false
      let name = ""
      let dataArr = []

      // 名称
      let arrNmae = []
      // 数据
      let statArr =  []
      let staffArr = []
      let workStaffArr = []
      let statInNum = []

      if (val == "request") {
        dataArr = this.data1
        state = true
        name = "paixu"
      } else if (val == "on_change") {
        dataArr = this.dimensionList
        state = true
        name = "shaixuan"
      } else if (val == "dropdown_request") {
        dataArr = this.data2
        state = true
        name = "dropdown_paixu"
      } else if (val == "dropdown_on_change") {
        state = true
        name = "dropdown_shuaixuan"
      }

      if (state) {
        if (name == "paixu") {
          for (let i = 0; i < dataArr.length; i++) {
            for (let j = 0; j < this.dimensionList.length; j++) {
              if (this.dimensionList[j].id == dataArr[i].dimensionId) {
                arrNmae.push(this.dimensionList[j].name)
              }
            }
          }
          
          for (let i = 0; i < dataArr.length; i++) {
            statArr.push(dataArr[i].statNum)
            staffArr.push(dataArr[i].staffNum)
            workStaffArr.push(dataArr[i].workStaffNum)
            statInNum.push(dataArr[i].statInNum)
          }
        } else if (name == "shaixuan") {
          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < dataArr.length; j++) {
              if (dataArr[j].id == arr[i].dimensionId) {
                arrNmae.push(dataArr[j].name)
              }
            }
          }


          for (let i = 0; i < arr.length; i++) {
            statArr.push(arr[i].statNum)
            staffArr.push(arr[i].staffNum)
            workStaffArr.push(arr[i].workStaffNum)
            statInNum.push(arr[i].statInNum)
          }
        } else if (name == "dropdown_paixu") {
          for (let i = 0; i < dataArr.length; i++) {
            arrNmae.push(dataArr[i].orgName)
            statArr.push(dataArr[i].statNum)
            staffArr.push(dataArr[i].staffNum)
            workStaffArr.push(dataArr[i].workStaffNum)
            statInNum.push(dataArr[i].statInNum)
          }
        } else if (name == "dropdown_shuaixuan") {
          for (let i = 0; i < arr.length; i++) {
            arrNmae.push(arr[i].orgName)
            statArr.push(arr[i].statNum)
            staffArr.push(arr[i].staffNum)
            workStaffArr.push(arr[i].workStaffNum)
            statInNum.push(arr[i].statInNum)
          }
        }
      }

      // 没有数据或者请求错误，把上次的数据清除
      if (val == 1) {
        arr = []
        statArr = []
        staffArr = []
        workStaffArr = []
        statInNum = []
      }

      this.echart(arrNmae, {statArr,staffArr,workStaffArr,statInNum})
    },
    // 大屏
    echart(arrNmae,obj) {
      if (this.state == "1") {
        var chartDom = document.getElementById('main')
      } else if (this.state == "2") {
        var chartDom = document.getElementById('dropdown')
      }

      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: arrNmae
        },
        dataZoom: [
					{
						show: true,//dataZoom，框选区域缩放，自动与存在的dataZoom控件同步，分别是启用，缩放后退
						start: 0,
						end: 10
					}
				],
        series: [
          {
            name: '机构数',
            type: 'bar',
            data: obj.statArr,
            itemStyle:{
              normal:{
                color:'#5087ec'
              }
            }
          },
          {
            name: '管理员数',
            type: 'bar',
            data: obj.staffArr,
            itemStyle:{
              normal:{
                color:'#68bbc4'
              }
            }
          },
          {
            name: '在职',
            type: 'bar',
            data: obj.workStaffArr,
            itemStyle:{
              normal:{
                color:'#58a55c'
              }
            }
          },
          {
            name: '已开通账号机构数',
            type: 'bar',
            data: obj.statInNum,
            itemStyle:{
              normal:{
                color:'#ffb223'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    // 大屏筛选
    on_change(val) {
      let data = []
      let arr = []
      if (this.state == "1") {
        data = this.data1
      } else if (this.state == "2") {
        data = this.data2
      }

      
      
      for (let i = 0; i < data.length; i++) {
        let A = JSON.stringify(data[i])
        arr.push(JSON.parse(A))
      }

      if (val == 1) {
        arr.sort(this.sorting("statNum"))
      } else if (val == 2) {
        arr.sort(this.sorting("staffNum"))
      } else if (val == 3) {
        arr.sort(this.sorting("workStaffNum"))
      }  else if (val == 4) {
        arr.sort(this.sorting("statInNum"))
      } else {
        if (this.state == 1) {
          this.echart_data("request")
        } else if (this.state == 2) {
          this.echart_data("dropdown_request")
        }
        return
      }
      if (this.state == 1) {
        this.echart_data("on_change", arr)
      } else if (this.state == 2) {
        this.echart_data("dropdown_on_change", arr)
      }
    },
    // 排序
    sorting(val) {
      return function (obj1,obj2) {
        var value1=obj1[val];
        var value2=obj2[val];
        if(value1>value2){
          return -1
        }else if(value1<value2){
          return 1
        }else {
          return 0
        }
      }
    },
    //筛选页面的头部地区
    screening_changeCasader(arr, item) {
      if (arr.length != 0) {
        this.dropdown_topArr = arr
        this.dropdown_request()
      } else {
        this.orgCode1 = "44"
        this.screeningLabel = ""
        this.dropdown_resetNum += 1
        this.data2 = []
        this.prompt = "请选择地区"
      }
    },
    // 首页进筛选页的维度名字
    dropdown_list (obj) {
      this.dropdown_dimensionId = obj.id
      this.dropdownName = obj.name
      this.dropdown_request()
    },
    // 机构地区保存
    institutions_changeCasader(arr) {
      this.institutions_topArr = arr
      if (arr.length == 0){
        this.orgCode2 = "44"
        this.screeningLabel = ""
        this.institutions_resetNum += 1
        this.data2 = []
      }
    },
    // 机构维度保存
    institutions_list(obj) {
      this.institutions_dimensionId = obj.id
      this.dropdownName = obj.name
    },
    // 导出
    async daochu() {
      if (this.data3.length == 0) {
        this.$Notice.error({
          title: '当前页面数据为空，不能导出',
        });
        return
      }
      if(this.daochu_state == true) {
        this.instance({title: "提示", content: "正在导出请勿重复点击"})
        return
      }
      
      //判断是否在导出过程
      this.daochu_state = true

      this.$Message.config({
        top: 50,
        content: "正在导出，请稍等",
        duration: 3
      });
      let dao_page = 1
      let dao_arr = []
      let maxCount = this.maxCount
      let zong_page = Math.ceil(maxCount / 100)
      let state = false

      this.$Notice.info({
        title: '提示',
        desc: '正在导出中，请稍后...'
      });

      for(dao_page; dao_page <= zong_page; dao_page++) {
        if(this.daochu_state == false) {
          return
        }
        let url = ""
        if (this.open_state) {
          url = "/gateway/api/symanage/pc/manages/selectStatPage"
        } else {
          url = "/gateway/api/symanage/pc/manages/selectOpenedStatPage"
        }
        await this.$get(url, {
          orgCode: this.institutions_topArr[this.institutions_topArr.length - 1],
          dimensionId: this.institutions_dimensionId,
          page: dao_page,
          pageSize: "100"
        }).then(res => {
          if(res.code == "200") {
            for(let i = 0; i < res.dataList.length; i++) {
              if(res.dataList[i].staffNum == null) {
                res.dataList[i].staffNum = 0
              }

              if(res.dataList[i].workStaffNum == null) {
                res.dataList[i].workStaffNum = 0
              }

              for (let k = 0; k < this.dimensionList.length; k++) {
                if (this.dimensionList[k].id == res.dataList[i].dimensionId) {
                  res.dataList[i].dimensionId = this.dimensionList[k].name
                }
              }
            }
            dao_arr.push(...res.dataList)

            if(dao_page == zong_page) {
              this.daochu_state = false
            }
          } else {
            state = true
            this.$Notice.error({
              title: '导出失败，服务有波动',
            });
            if(dao_page == zong_page) {
              this.daochu_state = false
            }
          }
        })

        if(state) {
          return
        }
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>您正在导出${this.dropdownName}的社区架构数据，是否继续？</p>`,
        onOk: () => {
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0
          });
          let tHeader = [
            "区",
            "街道",
            "社区",
            "机构名称",
            "机构类型",
            "服务范围",
            "管理员数",
            "在职"
          ];
          let filterVal = [
            "zoneName",
            "streetName",
            "communityName",
            "orgName",
            "dimensionId",
            "orgScopeName",
            "staffNum",
            "workStaffNum"
          ]
          this.$core.exportExcel(tHeader, filterVal, dao_arr, "数据分析列表");
          this.$Message.destroy();
        }
      });
      this.daochu_state == false
    },
    on_changePage(val) {
      this.page = val
      this.institutions_request()
    },
    // 提示导出中断
    instance (obj) {
      this.$Modal.confirm({
        title: obj.title,
        content: `<p>${obj.content}</p>`,
        onOk: () => {
          if(obj.state == 2) {
            this.daochu_state = false
          }
        },
        onCancel: () =>{
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.portalSystem_accountopening {
  .accountopening_table {
    width: 100%;
    border-top: 1px solid #e8eaec;
    border-left: 1px solid #e8eaec;
    border-spacing: 0;

    .accountopening_table_tr {
      width: 100%;

      .table_th {
        width: 20%;
        height: 40px;
        border-bottom: 1px solid #e8eaec;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;

        /deep/ .input > .ivu-cascader > .ivu-cascader-rel > .ivu-input-wrapper >  .ivu-input{
          width: 100%;
          height: 40px;
          border-radius: 0;
          border: 0px;
        }

        /deep/ .input > .ivu-cascader {
          margin-right: 0;
        }
      }

      .table_td {
        border-bottom: 1px solid #e8eaec;
        border-right: 1px solid #e8eaec;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
      }

      .table_td_wu {
        border-bottom: 1px solid #e8eaec;
        border-right: 1px solid #e8eaec;
        height: calc(100vh - 100px);
        line-height: 300px;
        text-align: center;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
      }
    }

    .accountopening_table_tr > .hover:first-child:hover{
      color: #fff;
      color: #6190e8;
      text-decoration: underline;
      cursor: pointer;;
    }
    .accountopening_table_tr > .hover:nth-child(2):hover{
      color: #fff;
      color: #6190e8;
      text-decoration: underline;
      cursor: pointer;;
    }
    .accountopening_table_tr > .hover:nth-child(5):hover{
      color: #fff;
      color: #6190e8;
      text-decoration: underline;
      cursor: pointer;;
    }
  }

  .accountopening_slele {
    display: block;
    margin-top: 50px;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .main {
      margin-top: 40px;
      width: 90%;
      height: 85%;
    }

    .slele {
      position: absolute;
      top: 0;
      right: 18%;
      
    }
  }

  .screening_Dropdown {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search {
  .search_dropdown {
    margin: 0 40px;
    border: 1px solid #dcdee2;
    min-width: 200px;
    padding: 4px 7px;
    box-sizing: border-box;
    height: 32px;
    border-radius: 4px;
    backface-visibility: #fff;

    .search_dropdown_a {
      color: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.total {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
</style>